export const environment = {
  production: false,
  backendURL: 'https://us-central1-polygon-credit-manager.cloudfunctions.net',
  firebase: {
    apiKey: 'AIzaSyCZisFb5auTSHYc6xyldooH9WbVa5MIvNo',
    authDomain: 'polygon-credit-manager.firebaseapp.com',
    projectId: 'polygon-credit-manager',
    storageBucket: 'polygon-credit-manager.appspot.com',
    messagingSenderId: '10963176372',
    appId: '1:10963176372:web:80d88cacf16c32cd8740e1'
  }
};
